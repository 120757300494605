import Swal from 'sweetalert2'

class convertingPopupCore {

    constructor() {
        this.pageID = document.body.getAttribute('data-route-id')?.toUpperCase();
    }

    getPromotionPopUpHTML(popUpPromoID) {
            let view = {
                parameters: {
                    view:'pages/components/promoPopUp.php',
                    dynamicValue: popUpPromoID,
                }
            }

            $.ajax({
                method: 'POST',
                url: '/ajax/ajaxhandler.php?class=rest&method=getDynamicComponent' ,
                data: view

            }).done((data, status) => {
                Swal.fire({
                    html: data.view,
                    customClass: {
                        popup:  'swal-promopopup-container',
                        htmlContainer:  'promopopup-container',
                    },
                    showCloseButton: true,
                    showConfirmButton: false,
                    allowOutsideClick: false,
                })
                if (document.querySelector('body').getAttribute('data-site-type') == 'pnp') {
                    PNP.initialize();
                }
            })
    }

    //OPENING POPUP
    showPOPUP() {
        wzCustomFunctions.setLocalStorageItem('cpopup', 'popoffer', 100000);
        this.convertingPopUp();
    }

    convertingPopUp() {
        //REFACTOR TO OBJECT VALUES TO NOT SEARCH FOR PROMOTION EVERY TIME
        let shown = false;
        let loginStatus = document.body.getAttribute('data-logged-in') === "1"?'LOGGEDIN':'LOGGEDOUT';
        Object.entries(window.promotionVariables).forEach(([promotion, values]) => {
                let showPopUp = values['SHOW_POPUP_TRIGGERS'] && !values['SHOW_POPUP_TRIGGERS']['BLOCKEDCOUNTRIES'].includes(document.body.getAttribute('data-actual-geo').toUpperCase())
                    && (values['SHOW_POPUP_TRIGGERS'][loginStatus][this.pageID] 
                        || (values['SHOW_POPUP_TRIGGERS'][loginStatus]['DEFAULT'] && !values['SHOW_POPUP_TRIGGERS'][loginStatus][this.pageID])
                    );
                if (showPopUp) {
                    //ON PAGE LOAD
                    if (values['SHOW_POPUP_TRIGGERS']['ONLOAD'] && shown == false) {
                        this.getPromotionPopUpHTML(promotion);
                        shown = true;
                    }
                    //ON BUTTON CLICK - RECEIVE BUTTON SELECTOR
                    if (values['SHOW_POPUP_TRIGGERS']['BUTTON'] && shown == false) {
                        document.querySelector(values['SHOW_POPUP_TRIGGERS']['BUTTON']).addEventListener('click', this.showPOPUP(promotion));
                        shown = true;
                    }
                    //ON TIME ON PAGE - RECEIVE TIME TO SHOW POPUP IN MILISECONDS
                    if (values['SHOW_POPUP_TRIGGERS']['TIMEONPAGE'] && shown == false) {
                        setTimeout(() => {
                            this.getPromotionPopUpHTML(promotion);
                            shown = true;
                        }, values['SHOW_POPUP_TRIGGERS']['TIMEONPAGE']);
                    }
                    //ON SCROLL
                    if (values['SHOW_POPUP_TRIGGERS']['ONSCROLL'] && shown == false) {
                        const showIfScrolled = () => {
                            if (window.scrollY > 1500) {
                                this.getPromotionPopUpHTML(promotion);
                                shown = true;
                                window.removeEventListener('scroll', showIfScrolled);
                            }
                        };
                        window.addEventListener('scroll', showIfScrolled);
                    }
                    // ON TAB FOCUS CHANGE
                    if (values['SHOW_POPUP_TRIGGERS']['FOCUSCHANGE'] && shown == false) {
                        document.addEventListener('visibilitychange', () => {
                            if (document.visibilityState == 'hidden') {
                                this.getPromotionPopUpHTML(promotion);
                                shown = true;
                            }
                        },{ once: true });
                        
                    }
                    //ON CUSTOM EVENT
                    // if (values['SHOW_POPUP_TRIGGERS']['ONLOAD']) {
                    //     document.addEventListener(this.convertingPopUpTriggers.customEvent, this.showPOPUP(promotion));
                    // }
                }
        });
    }

    init() {
        let _this = this;
        window.addEventListener("load", (event) => {
            if ((!wzCustomFunctions.getLocalStoreItemWithExpiry('cpopup', 'popoffer'))) {
                if(typeof getCookie !== 'undefined') {
                    let cookieConsentInfo = getCookie('cgcookieconsent');
                    if(cookieConsentInfo) {
                        _this.showPOPUP();
                    } else {
                        const convertingPopUpInterval = setInterval(() => {
                            if(getCookie('cgcookieconsent')) {
                                _this.showPOPUP();
                                clearInterval(convertingPopUpInterval);
                            }
                        }, 2000);
                    }
                } else {
                    _this.showPOPUP();
                }
            }
        });
    }

}

window.convertingPopup  = new convertingPopupCore();

window.convertingPopup.init();
